import { template as template_a172b6f3db3f4e85b0f8ed232f6ff982 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a172b6f3db3f4e85b0f8ed232f6ff982(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
