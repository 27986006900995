import { template as template_1c4339c8efeb42b7bde9f4c0b449041e } from "@ember/template-compiler";
const FKLabel = template_1c4339c8efeb42b7bde9f4c0b449041e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
